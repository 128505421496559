import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


const API = process.env.REACT_APP_POST_API_KEY;


const fetchPostData = createAsyncThunk(
    "postData/fetchPostData",
    async (args, { getState, dispatch }) => {
        const localStorageData = localStorage.getItem('postData');
        const dataAge = localStorage.getItem('postDataTimestamp');
        const now = new Date();

        // Check if the data is not older than 24 hours (3600000 milliseconds)
        if (localStorageData && dataAge && now - new Date(dataAge) < 1800000) {
            return JSON.parse(localStorageData);
        }

        // Fetch data if it's not in localStorage or is outdated
        const response = await fetch(`${API}`);
        if (!response.ok) {
            throw new Error("Sorry something went wrong");
        } 
        const data = await response.json();

        // Store the fetched data in localStorage with a timestamp
        localStorage.setItem('postData', JSON.stringify(data));
        localStorage.setItem('postDataTimestamp', new Date().toISOString());

        return data;
    }
);

const postDataSlice = createSlice({
    name: "postData",
    initialState: {
        postData: null,
        error: null,
        loading: "idle",
    },
    reducers: {},
    extraReducers: {
        [fetchPostData.pending]: (state) => {
            state.loading = "pending";
        },
        [fetchPostData.fulfilled]: (state, action) => {
            state.loading = "fulfilled";
            state.postData = action.payload;
        },
        [fetchPostData.rejected]: (state, action) => {
            state.loading = "rejected";
            state.error = action.error.message;
        },
    },
});

export const { } = postDataSlice.actions;
export default postDataSlice.reducer;
export { fetchPostData };
