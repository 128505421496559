import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const API = process.env.REACT_APP_PRODUCTS_API_KEY;

const fetchProductsData = createAsyncThunk(
    "productsData/fetchProductsData",
    async (args, { getState, dispatch }) => {
        const localStorageData = localStorage.getItem('productsData');
        const dataAge = localStorage.getItem('productsDataTimestamp');
        const now = new Date();

        // Check if the data is not older than 24 hours (1800000 milliseconds)
        if (localStorageData && dataAge && now - new Date(dataAge) < 1800000) {
            return JSON.parse(localStorageData);
        }

        // Fetch data if it's not in localStorage or is outdated
        const response = await fetch(`${API}?acf_format=standard&per_page=99`);
        if (!response.ok) {
            throw new Error("Sorry something went wrong");
        }
        const data = await response.json();

        // Store the fetched data in localStorage with a timestamp
        localStorage.setItem('productsData', JSON.stringify(data));
        localStorage.setItem('productsDataTimestamp', new Date().toISOString());

        return data;
    }
);

const productsDataSlice = createSlice({
    name: "productsData",
    initialState: {
        productsData: null,
        error: null,
        loading: "idle",
    },
    reducers: {},
    extraReducers: {
        [fetchProductsData.pending]: (state) => {
            state.loading = "pending";
        },
        [fetchProductsData.fulfilled]: (state, action) => {
            state.loading = "fulfilled";
            state.productsData = action.payload;
        },
        [fetchProductsData.rejected]: (state, action) => {
            state.loading = "rejected";
            state.error = action.error.message;
        },
    },
});

export const { } = productsDataSlice.actions;
export default productsDataSlice.reducer;
export { fetchProductsData };
