import { useSelector } from "react-redux";
import { useState } from "react";
import "./Form.css";
import PuffLoader from "react-spinners/PuffLoader";

function Form({ handler, isLoading, isSent, hasError }) {
  const [formState, setFormState] = useState({});
  const { pageData } = useSelector((state) => state.pageData);
  const formClassName = isSent ? "form--sent" : "";


  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    });
  };

  const handleFormSubmit = (e) => {
    handler(e, formState);
  };

  const override = {
    display: "block",
    margin: "20px auto",
    borderColor: "red",
  };
  if (Object.keys(pageData).length !== 0) {
    return (
      <form id="form-1" className={formClassName} onSubmit={handleFormSubmit}> 
        <div className="flex justify-between">
          <div className="flex flex-col first--input">
            <label className="text-[18px] font-normal font-nunito text-white">
              {pageData.acf.imie}<span className="text-default-red">*</span> 
            </label>
            <input
              required
              onChange={(e) => handleFieldChange("your-name", e)}
              type="text"
              placeholder="Introdu numele"
            />
          </div>
          <div className="flex flex-col w-1/2">
            <label className="text-[18px] font-normal font-nunito text-white">
            {pageData.acf.nazwisko}<span className="text-default-red">*</span>
            </label>
            <input
              required
              onChange={(e) => handleFieldChange("your-surname", e)} 
              type="text"
              placeholder="Introduceți numele"
            />
          </div>
        </div>
        <div className="flex mt-[25px] justify-between">
          <div className="flex flex-col w-8/12">
            <label className="text-[18px] font-normal font-nunito text-white">
            {pageData.acf.nazwa_firmy}<span className="text-default-red">*</span>
            </label>
            <input
              required
              onChange={(e) => handleFieldChange("comapny", e)}
              type="text"
              placeholder="Introduceți numele companiei dvs"
            />
          </div>
          <div className="flex flex-col w-1/4">
            <label className="text-[18px] font-normal font-nunito text-white">
            {pageData.acf.nip}<span className="text-default-red">*</span>
            </label>
            <input
              required
              onChange={(e) => handleFieldChange("nip", e)}
              type="text"
              placeholder="0000000000000"
            />
          </div>
        </div>
        <div className="flex mt-[25px] justify-between">
          <div className="flex flex-col first--input">
            <label className="text-[18px] font-normal font-nunito text-white">
            {pageData.acf.numer_telefonu_formularz}<span className="text-default-red">*</span>
            </label>
            <input
              required
              onChange={(e) => handleFieldChange("phone", e)}
              type="text"
              placeholder="de exemplu. +44 123 456 789"
            />
          </div>
          <div className="flex flex-col w-1/2">
            <label className="text-[18px] font-normal font-nunito text-white">
            {pageData.acf.adresemail}<span className="text-default-red">*</span>
            </label>
            <input
              required
              onChange={(e) => handleFieldChange("email", e)}
              type="email"
              placeholder="adresa@email.com"
            />
          </div>
        </div>
        <div className="flex flex-col mt-[25px]">
          <label className="text-[18px] font-normal font-nunito text-white">
          {pageData.acf.wiadomosc}<span className="text-default-red">*</span>
          </label>
          <textarea
            required
            onChange={(e) => handleFieldChange("message", e)}
            type="textarea"
            placeholder="Introduceți mesajul dvs. aici"
          />
        </div>
        <div className="checkbox flex mt-[25px] justify-between items-baseline">
          <input
            className="checkbox-flip"
            type="checkbox"
            required
            id="acceptance"
            name="acceptance"
            value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus ultrices in."
          />
          <label
            className="flex items-center text-[14px] max-w-[620px] font-normal font-nunito text-white"
            htmlFor="acceptance"
          >
            <span></span>
            <div style={{display: "inline-flex"}} ><p className="text-white">Accept <a href="/politica-de-confidentialitate">{pageData.acf.zgoda}</a></p><p className="text-default-red">*</p></div>
          </label>
        </div>
        <input
          className="max-h-[43px] mx-auto md:mx-0 mt-[30px] border-0 mb-[20px] lg:mb-[40px] w-[115px] flex cursor-pointer justify-center items-center flex bg-white hover:bg-white/75 text-black font-nunito font-bold p-1  rounded-[8px]"
          type="submit"
          value="Trimite"
        />
        {isLoading ? (
          <PuffLoader
            color={"#e7345b"}
            loading={isLoading}
            cssOverride={override}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          ""
        )}
        {isSent ? (
          <p className="successForm font-nunito">
            {pageData.acf.informacja_o_sukcesie}
          </p>
        ) : (
          ""
        )}
      </form>
    );
  }
}

export default Form;
