const SingleContent = ({ title, content }) => {
  return (
    <div className="max-w-[1200px] px-2 lg:px-4 container mx-auto">
      <h2 className="lg:text-[34px] text-center lg:text-left md:text-[24px] text-[19px] text-black font-bold font-nunito">
        {title}
      </h2>
      <div
          className="lg:pb-[60px] pb-[25px] break-words	 max-w-[1120px] xl:px-0 md:px-5 px-3 font-nunito"
          dangerouslySetInnerHTML={{ __html: content }}
        />
    </div>
  );
};

export default SingleContent;
