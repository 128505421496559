import { useEffect } from "react";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import Header from "../components/Header";

const PrivacyPage = () => {
  const { policyData } = useSelector((state) => state.policyData);
  const { pageData } = useSelector((state) => state.pageData);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (policyData) {
    return (
      <>
        <Header />
        <div className="container mx-auto text-center md:text-left my-4 md:pl-[18px] lg:pl-[25px] xl:pl-[36px] 2xl:pl-[6px] 2xl:max-w-[1350px] md:mt-[31px] md:mb-[60px]">
          <h4 className="text-[14px]  text-[#acacac] font-normal font-nunito">
            {pageData.acf.strona_glowna.title} \{" "}
            <b>{policyData.title.rendered}</b>
          </h4>
        </div>
        <div className="mx-auto max-w-[1100px] policy--styles font-nunito">
          <h1 className="xl:px-0 px-5">{policyData.title.rendered}</h1>
          <div
            className="lg:pb-[60px] pb-[25px] mx-auto max-w-[1100px] xl:px-0 px-5 product--content font-nunito"
            dangerouslySetInnerHTML={{ __html: policyData.content.rendered }}
          />
        </div>
        <Footer />
      </>
    );
  }
};

export default PrivacyPage;
