import Homepage from './pages/Homepage';
import { Routes, Route } from 'react-router-dom'
import ProductsPage from './pages/ProductsPage';
import SingleProductPage from './pages/SingleProductPage';
import { useDispatch, useSelector } from "react-redux";
import { fetchPageData } from "../src/utilities/slices/fetchData";
import { fetchProductsData } from "../src/utilities/slices/fetchProducts";
import { fetchPostData } from './utilities/slices/fetchPost';
import { useEffect, useState } from "react";
import { fetchPolicyData } from './utilities/slices/fetchPolicy';
import { fetchInfoData } from './utilities/slices/fetchInfo';
import SinglePostPage from './pages/SinglePostPage';
import PrivacyPage from './pages/PrivacyPage';
import ToUPage from './pages/ToUPage'
import { Oval } from 'react-loader-spinner'
import Logo from "./assets/multigastro_logo-1.png"
import { Helmet } from 'react-helmet';




const App = () => {
  const dispatch = useDispatch();
  const { productsData } = useSelector((state) => state.productsData);
  const [fetchProduct, setFetchProduct] = useState([]);
  const { postData } = useSelector((state) => state.postData);
  const [fetchPost, setFetchPost] = useState([]);
  const { pageData } = useSelector((state) => state.pageData);
  const [sliderData, setSliderData] = useState({
    ...pageData,
  });
  const { policyData } = useSelector((state) => state.policyData);
  const [fetchedPolicyData, setFetchedPolicyData] = useState({
    ...policyData,
  });
  const { infoData } = useSelector((state) => state.infoData);
  const [fetchedInfoData, setFetchedInfoData] = useState({
    ...infoData,
  });

  useEffect(() => {
    dispatch(fetchPageData());
    dispatch(fetchProductsData());
    dispatch(fetchPostData());
    dispatch(fetchPolicyData());
    dispatch(fetchInfoData());

  }, [dispatch]);

  useEffect(() => {
    if (pageData !== null) {
      setSliderData({
        ...pageData,
      });
    }

    if (policyData !== null) {
      setFetchedPolicyData({
        ...policyData,
      });
    }

    if (infoData !== null) {
      setFetchedInfoData({
        ...infoData,
      });
    }

    if (postData !== null) {
      setFetchPost(postData);
    }

    if (productsData !== null) {
      setFetchProduct(productsData);
    }

  }, [pageData, postData, productsData]);


  const exportedData = fetchProduct.map((item) => ({
    id: item.id,
    acf: item.acf,
    slug: item.slug,
    content: item.content.rendered
  }));

  const exportedPosts = fetchPost.map((item) => ({
    id: item.id,
    acf: item.acf,
    slug: item.slug,
    title: item.title.rendered,
    content: item.content.rendered
  }));
  // Selector to check if any of the data is still loading
  const isPageDataLoading = useSelector((state) => state.pageData.loading);
  const isInfoDataLoading = useSelector((state) => state.infoData.loading);
  const isPolicyDataLoading = useSelector((state) => state.policyData.loading);
  const isPostDataLoading = useSelector((state) => state.postData.loading);
  const isProductsDataLoading = useSelector((state) => state.productsData.loading);

  // Check if any of the data is still in the 'pending' state
  const isLoading =
    isPageDataLoading === 'pending' ||
    isInfoDataLoading === 'pending' ||
    isPolicyDataLoading === 'pending' ||
    isPostDataLoading === 'pending' ||
    isProductsDataLoading === 'pending';

  // Use the isLoading flag to conditionally render the loader or the main app
  if (isLoading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
      }}>
        <Oval
          visible={true}
          height="130"
          width="130"
          color="#e7345b"
          secondaryColor="#e7345b"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
        <img
          className="m-5 max-w-[12rem] sm:max-w-[18rem]"
          src={Logo}
          alt=""
        />
      </div>
    );
  }


  if (Object.keys(sliderData).length !== 0) {
    return (
      <>
        <Helmet>
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KS2JMP9R');`}
          </script>
        </Helmet>
        <Routes>
          <Route path='/'>
            <Route index={true} element={<Homepage />} />
          </Route>
          <Route path={sliderData.acf.produkty.title.toLowerCase()} element={<ProductsPage data={fetchProduct} />} />
          {exportedData.map((item) => {
            return <Route path={sliderData.acf.produkty.title.toLowerCase() + "/" + item.slug} element={<SingleProductPage data={item.acf} content={item.content} />} />
          })}
          {exportedPosts.map((item) => {
            return <Route path={"postari/" + item.slug} element={<SinglePostPage title={item.title} content={item.content} />} />
          })}
          <Route path={fetchedPolicyData.slug} element={<PrivacyPage />} />
          <Route path={fetchedInfoData.slug} element={<ToUPage />} />

          <Route path="*" element={<Homepage />} />
        </Routes>
      </>
    );
  }
}

export default App;
