import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icon from "../assets/infoicon.svg";
import tooltip from "../assets/tooltip.svg";
import SingleProductSlider from "./SingleProductSlider";
import { useState } from "react";

const SingleSlider = ({ data, fields }) => {
  const [isShown, setIsShown] = useState(false);

  const handleClickScroll = () => {
    const element = document.getElementById("contact-form");
    element.scrollIntoView({ behavior: "smooth" });
  };


  return (
    <>
      {" "}
      <div className="flex justify-evenly items-center container mx-auto gap-8 lg:gap-6 xl:px-0 px-5 flex-col lg:flex-row">
        <div className="h-auto w-full md:w-[430px]">
          <SingleProductSlider data={data} />
        </div>
        <div className="max-w-[545px]">
          <h2 className="text-[25px] md:text-[26px] lg:text-[30px] max-w-[500px] mb-[20px] lg:mb-[30px] font-bold font-nunito">
            {data.nazwa_produktu}
          </h2>
          <h4 className="text-[18px] mb-[25px] font-bold font-nunito">
            {fields.tresc_pola_kod_produktu}
            <span className="text-[18px] ml-[15px] mb-[25px] font-normal font-nunito">
              {data.kod_produktu}
            </span>
          </h4>
          <h4 className="text-[18px] mb-[25px] font-bold font-nunito">
            {fields.tresc_pola_dostepnosc}
            {data.dostepnosc ? (
              <span className="text-[18px] text-[#00730b] ml-[15px] mb-[25px] font-normal font-nunito">
                {fields.tekst_jesli_produkt_jest_dostepny}
              </span>
            ) : (
              <span className="text-[18px] text-[#E7345B] ml-[15px] mb-[25px] font-normal font-nunito">
                {fields.tekst_jesli_produkt_jest_niedostepny}
              </span>
            )}
          </h4>

          <h4 className="text-[18px] mb-[25px] font-bold font-nunito relative">
            {fields.tresc_pola_wysylka}
            <span className="inline-flex text-[18px] ml-[15px] mb-[5px] font-normal font-nunito">
              {data.wysylka}{" "}
              {data.informacja_przy_wysylce ? (
                <img
                  onMouseEnter={() => setIsShown(true)}
                  onMouseLeave={() => setIsShown(false)}
                  className="ml-[15px]"
                  src={icon}
                />
              ) : null}
            </span>
            {data.informacja_przy_wysylce ? (
              <div
                className={`${
                  isShown ? "flex" : "hidden	"
                }  items-baseline	absolute delivery--tooltip`}
              >
                {" "}
                <img className="ml-[3px] tooltip-arrow" src={tooltip} />
                <p className="max-w-[250px] py-[10px] px-[15px] text-[10px]  font-normal font-nunito rounded-lg text-white	bg-black">
                  {data.informacja_przy_wysylce}
                </p>
              </div>
            ) : null}
          </h4>
          <h4 className="flex items-center text-[18px] mb-[25px] font-bold font-nunito">
            {fields.tresc_pola_cena_netto}
            <span className="text-default-red leading-7	 ml-[40px] font-bold text-[30px] md:text-[42px] font-nunito">
              {data.cena} RON
            </span>
          </h4>
          <button
            onClick={handleClickScroll}
            class="max-h-[50px] w-100  max-w-[370px] flex justify-center items-center flex bg-default-red hover:bg-default-red/75 text-white font-bold p-3  rounded-full"
          >
            <p
              
              className="flex md:text-[20px] text-[17px] font-bold font-nunito"
            >
              {fields.zapytaj_o_produkt.title}
            </p>
          </button>
          <p className="text-[12px] mt-[23px] font-normal font-nunito">
            {data.tekst_pod_cena_netto}
          </p>
        </div>
      </div>
    </>
  );
};

export default SingleSlider;
