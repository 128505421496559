import arrowDown from "../assets/arrowDown.svg";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import FormInfo from "./FormInfo";

const Footer = ({}) => {
  const [displayAmount, setDisplayAmout] = useState(6);
  const [showMore, setShowMore] = useState(false);
  const { pageData } = useSelector((state) => state.pageData);
 

  
  const displayHandler = (e) => {
    e.preventDefault();
    setDisplayAmout(displayAmount + 50);
    setShowMore(true);
  };

  const hideHandler = (e) => {
    e.preventDefault();
    setDisplayAmout(displayAmount - 50);
    setShowMore(false);
  };


  if (Object.keys(pageData).length !== 0) {
    const reviewsAmount = pageData.acf.opinie.length;
    return (
      <>
        <div className="h-auto pt-8 pb-[60px] md:pt-20 bg-[#f1f1f1] relative">
          <div className="container mx-auto ">
            <div className="lg:max-w-[580px] flex justify-center">
              <h1 className="text-[32px] text-center lg:text-left max-w-[330px]  text-[#191919] font-bold font-nunito">
                {pageData.acf.opinie_klientow}
              </h1>
            </div>
            <div className="container mt-[40px] xl:pl-7 flex flex-wrap justify-center lg:justify-start		gap-[30px]">
              {pageData.acf.opinie.slice(0, displayAmount).map((item) => {
                
                return (
                  <div
                    key={item.id}
                    className="lg:p-[30px] w-full max-w-[330px] md:max-w-[550px] md:w-[310px] xl:w-[350px] 2xl:w-[430px] bg-white rounded-2xl flex items-start justify-start flex-col h-auto min-h-[250px] p-[15px]"
                  >
                    <img alt={item.title} src={item.logo} />
                    <h3 className="text-[22px] text-black font-bold mb-[3px] mt-[25px] font-nunito">
                      {item.tytul}
                    </h3>
                    <h4 className="text-[20px] text-black font-normal mb-[10px]  font-nunito">
                      {item.miejscowosc}
                    </h4>
                    <h5 className="text-[16px] text-black font-normal mb-[10px]  font-nunito">
                      {item.opis}
                    </h5>
                    <img
                      alt="stars"
                      src="https://multigastro.ro/wp/wp-content/uploads/2023/02/Group-15.png"
                    />
                  </div>
                );
              })}
            </div>
            <div className="lg:max-w-[510px] flex justify-center">
              {showMore ? (
                <button
                  onClick={hideHandler}
                  class={`${"flex"}  max-h-[43px] mt-[30px] w-100 max-w-[230px] justify-center items-center flex bg-black hover:bg-black/75 text-white font-bold p-4  rounded-[8px]`}
                >
                  <a href="/" className="flex text-base font-bold font-nunito">
                    {pageData.acf.przycisk_mniej_opinii_kopia}
                    <img
                      style={{ transform: "rotateX(180deg)" }}
                      className="ml-3"
                      src={arrowDown}
                      alt=""
                    />
                  </a>
                </button>
              ) : (
                <button
                  onClick={displayHandler}
                  className={`${"flex"}  max-h-[43px] mt-[30px] w-100 max-w-[200px] justify-center items-center flex bg-black hover:bg-black/75 text-white font-bold p-4  rounded-[8px]`}
                >
                  <a href="/" className="flex text-base font-bold font-nunito">
                    {pageData.acf.przycisk_wiecej_opinii}
                    <img className="ml-3" src={arrowDown} alt="" />
                  </a>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="h-auto py-8 md:py-20 bg-white">
          <div className="container mx-auto ">
            <div className="lg:max-w-[580px] flex justify-center">
              <h1 className="text-[32px] text-center lg:text-left max-w-[280px]  text-[#191919] font-bold font-nunito">
                {pageData.acf.nasze_nagrody}
              </h1>
            </div>
            <div className="container mt-[40px] xl:pl-7 flex flex-wrap justify-center lg:justify-start		gap-[30px]">
              {pageData.acf.nagrody.map((item) => {
                return (
                  <div
                    key={item.title}
                    className=" bg-white rounded-2xl flex items-start justify-start flex-col w-[315px] "
                  >
                    <img alt={item.title} src={item.obraz} />
                    <h3 className="text-[22px] text-black font-bold mb-[3px] mt-[25px] px-[15px] font-nunito">
                      {item.tytul}
                    </h3>
                    <p className="text-[16px] text-black font-normal mb-[10px]  px-[15px] mt-1  font-nunito">
                      {item.opis}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <FormInfo />
      </>
    );
  }
};

export default Footer;
