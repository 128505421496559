import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImageSlider = () => {
  const { pageData, loading } = useSelector((state) => state.pageData);
  const [showArrows, setShowArrows] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowArrows(true);
    }, 1000); // Set timeout for 1 second

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 2000,
    nextArrow: showArrows ? <SampleNextArrow /> : null,
    prevArrow: showArrows ? <SamplePrevArrow /> : null,
    arrows: showArrows,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          centerMode: true,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  // Render a loading indicator or placeholder while data is loading
  if (loading === "pending" || !pageData) {
    return <div>Loading image slider...</div>; // Or your custom loader/placeholder component
  }

  if (Object.keys(pageData).length !== 0) {
    return (
      <section className="baner">
        <Slider {...settings}>
          {pageData.acf.galeria_glowna.map((item) => (
            <div key={item.id}>
              <LazyLoadImage alt={item.title} src={item.url} />
            </div>
          ))}
        </Slider>
      </section>
    );
  } else {
    // Handle the scenario where data has loaded but is incorrect or empty
    return <div>Failed to load image slider data.</div>;
  }
};

export default ImageSlider;
