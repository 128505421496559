import Header from "../components/Header";
import Footer from "../components/Footer";
import Baner from "../components/Baner";
import ProductsList from "../components/ProductsList";
import { useEffect } from "react";

const ProductsPage = ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Baner />
      <ProductsList data={data} />
      <Footer />
    </>
  );
};
export default ProductsPage;
