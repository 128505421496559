import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow from "../assets/white--arrow.svg";
import { useState, React, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ImageSlider.css";
import { useSelector } from "react-redux";

export default function ProductsSlider() {
  const { pageData } = useSelector((state) => state.pageData);
  const { productsData } = useSelector((state) => state.productsData);
  const [fetchProduct, setFetchProduct] = useState([]);

  useEffect(() => {
    if (productsData !== null) {
      setFetchProduct(productsData);
    }
  }, [productsData]);


  const exportedData = fetchProduct.map((item) => ({
    id: item.id,
    acf: item.acf,
    slug: item.slug,
  }));

  const recommendedData = exportedData.filter(
    (item) => item.acf.polecane === true
  );
  

  var settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1624,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1454,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {recommendedData.map((item) => {
          return (
            <div className="" key={item.acf.id}>
              <Link
                to={`${pageData.acf.produkty.title.toLowerCase()}/${
                  item.slug
                }`}
              >
                <img alt={item.title} src={item.acf.zdjecie_glowne} />
              </Link>
              <Link
                to={`${pageData.acf.produkty.title.toLowerCase()}/${
                  item.slug
                }`}
              >
                <h3 className="max-w-[320px] mt-[35px] first--word mb-[10px] text-[18px] font-normal font-nunito">
                  {item.acf.nazwa_produktu}
                </h3>
              </Link>

              <h4 className="text-[26px] text-default-red font-bold mb-[20px] font-nunito">
                {item.acf.cena} RON
              </h4>
              <button className="max-h-[43px] w-auto  w-[110px] flex justify-center items-center flex bg-black hover:bg-black/75 text-white font-bold p-4  rounded-[8px]">
                <Link
                  to={`${pageData.acf.produkty.title.toLowerCase()}/${
                    item.slug
                  }`}
                  className="flex text-base font-bold font-nunito"
                >
                  {pageData.acf.przycisk_pokaz_przy_produkcie}
                  <img className="ml-3" src={arrow} alt="arrow" />
                </Link>
              </button>
            </div>
          );
        })}
      </Slider>
    </>
  );
}
