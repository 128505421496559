import "./Process.css";
import arrowRight from "../assets/arr-rig.svg";
import process from "../assets/process.png";
import {  useSelector } from "react-redux";

const Process = () => {
  const { pageData } = useSelector((state) => state.pageData);
  

  if (Object.keys(pageData).length !== 0) {
    return (
      <div className="lg:h-[980px] pb-14 lg:pb-0 custom--bg relative">
        <div className="container mx-auto">
          <div className="lg:max-w-[630px] flex justify-center lg:ml-[95px]">
            <h1 className="text-[32px] text-center lg:text-left max-w-[580px] pt-[40px] lg:pt-[100px] mb-[60px] text-white font-bold font-nunito">
            {pageData.acf.proces_dostawy_produktu}
            </h1>
          </div>
          <div className="flex flex-col md:flex-row lg:pl-7 items-center">
            <div className="relative border1 h-auto min-h-[180px] md:h-[315px] w-[335px] p-3 lg:p-5">
              <h2 className="text-default-red text-[48px] font-nunito">01</h2>
              <h4 className="text-white text-[26px] font-nunito">
              {pageData.acf.proces_1}
              </h4>
              <img
                alt="process"
                className="process--arrow--right"
                src={arrowRight}
              />
            </div>
            <div className="relative border2 h-auto min-h-[180px] md:h-[315px] w-[335px] p-3 lg:p-5">
              <h2 className="text-default-red text-[48px] font-nunito">02</h2>
              <h4 className="text-white text-[26px] font-nunito">
              {pageData.acf.proces_2}
              </h4>
              <img
                alt="process"
                className="process--arrow--right"
                src={arrowRight}
              />
            </div>
            <div className="relative border3 h-auto min-h-[180px] md:h-[315px] w-[335px] p-3 lg:p-5">
              <h2 className="text-default-red text-[48px] font-nunito">03</h2>
              <h4 className="text-white text-[26px] font-nunito">
              {pageData.acf.proces_3}
              </h4>
              <p className="mt-2 text-white text-[16px] font-nunito max-w-[200px]">
              {(pageData.acf.proces_3_tekst).replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, '')}
              </p>
              <img
                alt="process"
                className="process--arrow--down"
                src={arrowRight}
              />
            </div>
          </div>
          <div className="flex relative flex-col-reverse md:flex-row lg:pl-7 items-center">
            <div className="relative border4 h-auto min-h-[180px] md:h-[315px] w-[335px] p-3 lg:p-5">
              <h2 className="text-default-red text-[48px] font-nunito">06</h2>
              <h4 className="text-white text-[26px] font-nunito">
              {pageData.acf.proces_6}
              </h4>
              <h5 className="text-default-red text-[28px] font-nunito">
              {pageData.acf.proces_6_tekst}
              </h5>
            </div>
            <div className="relative border5 h-auto min-h-[180px] md:h-[315px] w-[335px] p-3 lg:p-5">
              <h2 className="text-default-red text-[48px] font-nunito">05</h2>
              <h4 className="text-white text-[26px] font-nunito"> {pageData.acf.proces_5}</h4>
              <img
                alt="process"
                className="process--arrow--left"
                src={arrowRight}
              />
            </div>
            <div className="relative border6 h-auto min-h-[180px] md:h-[315px] w-[335px] p-3 lg:p-5">
              <h2 className="text-default-red text-[48px] font-nunito">04</h2>
              <h4 className="text-white text-[26px] font-nunito">
              {pageData.acf.proces_4}
              </h4>
              <img
                alt="process"
                className="process--arrow--left"
                src={arrowRight}
              />
            </div>
            <img
              alt="process"
              className="2xl:block hidden absolute process--guy"
              src={process}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default Process;
