import { useState } from "react";
import placeholder from "../assets/placeholder.png";

const SingleTabs = ({ data, content, fields }) => {
  const [isDescription, setIsDescription] = useState(true);
  return (
    <div className="flex justify-center items-center flex-col">
      <div className="md:mt-[70px] my-[35px] md:mb-[50px] flex w-[91%] md:w-full justify-center xl:w-[1120px] bg-[#f2f2f2] min-h-[43px] h-auto	rounded-tabs">
        <button
          onClick={() => setIsDescription(true)}
          className={`w-[560px] rounded-tabs  text-[18px] ${
            isDescription ? "text-white" : ""
          } ${isDescription ? "bg-default-red" : ""} font-bold font-nunito`}
        >
          {fields.tresc_pola_opis_produktu}
        </button>
        <button
          onClick={() => setIsDescription(false)}
          className={`w-[560px] rounded-tabs ${
            isDescription ? "" : "bg-default-red"
          }  text-[18px] ${
            isDescription ? "" : "text-white"
          } font-bold font-nunito`}
        >
          {fields.tresc_pola_opinie_klientow}
        </button>
      </div>
      {isDescription ? (
        <div
          className="lg:pb-[60px] pb-[25px] max-w-[1120px] xl:px-0 px-5 product--content font-nunito"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : (
        <div className="md:pb-[80px] pb-[40px]">
          {data.opinie_do_produktu.map((item) => {
            return (
              <div className="flex items-start review--box max-w-[1120px] lg:p-[50px] p-[15px] md:p-[25px]">
                {item.zdjecielogo ? (
                  <img src={item.zdjecielogo.url} />
                ) : (
                  <img src={placeholder} />
                )}

                <div className="flex md:ml-[30px] ml-[15px] flex-col">
                  <h4 className="text-[18px] text-black font-bold font-nunito">
                    {item.nazwa}
                  </h4>
                  <p className="text-[14px] text-black font-normal font-nunito">
                    {item.tresc_opinii}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SingleTabs;
