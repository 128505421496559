import arrow from "../assets/white--arrow.svg";
import ProductsSlider from "./ProductsSlider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Products = () => {
  const { pageData } = useSelector((state) => state.pageData);


  if (Object.keys(pageData).length !== 0) {
    return (
      <>
        <div className="flex-col lg:flex-row container items-center justify-between flex mx-auto py-0 px-8 md:px-12 xl:px-0 lg:max-w-[1120px] ">
          <h2 className="text-[32px] mb-3 lg:mb-[0px]  font-bold font-nunito">
            {pageData.acf.polecane_produkty}
          </h2>

          <button className="max-h-[43px]   w-[274px] flex justify-center items-center flex bg-default-red hover:bg-default-red/75 text-white font-bold p-4  rounded-full">
            <Link to={pageData.acf.przycisk_zobacz_wszystkie_produkty.url}
              href={pageData.acf.przycisk_zobacz_wszystkie_produkty.url}
              className="flex text-base font-bold font-nunito"
            >
              {pageData.acf.przycisk_zobacz_wszystkie_produkty.title}
              <img className="ml-3" src={arrow} alt="" />
            </Link>
          </button>
        </div>
        <div className="mb-[80px] lg:mb-[130px] container mx-auto mt-[50px] pl-8 lg:pl-8 xl:pl-8 2xl:pl-8 lg:mt-[100px]">
          <ProductsSlider />
        </div>
      </>
    );
  }
};

export default Products;
