import arrow from "../assets/white--arrow.svg";
import { useSelector } from "react-redux";
import { useState } from "react";

const AboutUs = () => {
  const [isShown, setIsShown] = useState(false);
  const { pageData } = useSelector((state) => state.pageData);

  const handleText = () => {
    setIsShown(true);
  };

  if (Object.keys(pageData).length !== 0) {
    return (
      <div className="lg:mt-14 mt-6 container py-0 p-4 md:p-12 xl:p-0 mx-auto max-w-[1120px] ">
        <h2 className="text-[32px] mb-[20px] font-bold font-nunito">
          {pageData.acf.o_nas}
        </h2>

        <div className="flex-col	lg:flex-row flex">
          <div className="lg:w-full">
            <p
              className="md:columns-2 text-[18px] p-2 xl:p-0 py-0  font-normal leading-[30px] font-nunito"
              dangerouslySetInnerHTML={{ __html: pageData.acf.tekst_o_nas }}
            ></p>
          </div>
        </div>
        {isShown ? (
          <div className="flex-col mb-[50px] 2xl:mb-[110px] mt-5 lg:flex-row flex">
            <div className="lg:w-full">
              <p
                className="columns-1 text-[18px] p-2 xl:p-0 py-0 font-normal leading-[30px] font-nunito"
                dangerouslySetInnerHTML={{
                  __html: pageData.acf.tekst_o_nas_po_nacisnieciu_przycisku,
                }}
              ></p>
            </div>
          </div>
        ) : (
          ""
        )}

        {isShown ? (
          ""
        ) : (
          <button
            onClick={handleText}
            className="max-h-[43px] mt-[30px] mb-[50px] 2xl:mb-[110px] w- 100 max-w-[190px] flex justify-center items-center flex bg-black hover:bg-black/75 text-white font-bold p-4  rounded-[8px]"
          >
            <a className="flex text-base font-bold font-nunito">
              {pageData.acf.przycisk_o_nas}{" "}
              <img className="ml-3" src={arrow} alt="" />
            </a>
          </button>
        )}
      </div>
    );
  }
};

export default AboutUs;
