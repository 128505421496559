import Content from "../components/Content";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect } from "react";

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Content />
      <Footer />
    </>
  );
};
export default Homepage;
