import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const Baner = () => {
  const { pageData, loading } = useSelector((state) => state.pageData);
  const [showArrows, setShowArrows] = useState(false);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: showArrows && <SampleNextArrow />,
    prevArrow: showArrows && <SamplePrevArrow />,
    arrows: true,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          centerMode: true,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowArrows(true);
    }, 1000); // Delay arrows for 1 second

    return () => {
      clearTimeout(timer); // Clear the timer if the component unmounts
    };
  }, []);

  // Render a loading indicator or placeholder while data is loading
  if (loading === "pending" || !pageData) {
    return <div>Loading banner...</div>; // Or your custom loader/placeholder component
  }

  if (Object.keys(pageData).length !== 0) {
    return (
      <>
        <section className="baner">
          <Slider {...settings}>
            {pageData.acf.baner.map((item) => {
              return (
                <div key={item.id}>
                  <img alt={item.title} src={item.url} />
                </div>
              );
            })}
          </Slider>
        </section>
        <div className="container mx-auto text-center md:text-left my-4 md:pl-[18px] lg:pl-[25px] xl:pl-[36px] 2xl:pl-[6px] 2xl:max-w-[1350px] md:mt-[31px] md:mb-[60px]">
          <h4 className="text-[14px]  text-[#acacac] font-normal font-nunito">
            {pageData.acf.strona_glowna.title} \{" "}
            <b>{pageData.acf.produkty.title}</b>
          </h4>
        </div>
      </>
    );
  } else {
    // Optionally handle the scenario where data has loaded but is incorrect or empty
    return <div>Failed to load banner data.</div>;
  }
};

export default Baner;
