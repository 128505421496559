import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const API = process.env.REACT_APP_DATA_API_KEY;

const fetchPolicyData = createAsyncThunk(
    "policyData/fetchPolicyData",
    async (args, { getState, dispatch }) => {
        const localStorageData = localStorage.getItem('policyData');
        const dataAge = localStorage.getItem('policyDataTimestamp');
        const now = new Date();

        // Sprawdzenie czy dane nie są starsze niż 24 godziny (3600000 milisekund)
        if (localStorageData && dataAge && now - new Date(dataAge) < 1800000) {
            return JSON.parse(localStorageData);
        }

        // Pobieranie danych, jeśli nie ma ich w localStorage lub są przestarzałe
        const response = await fetch(`${API}/298`);
        if (!response.ok) {
            throw new Error("Sorry something went wrong");
        }
        const data = await response.json();

        // Zapisanie pobranych danych w localStorage wraz z timestampem
        localStorage.setItem('policyData', JSON.stringify(data));
        localStorage.setItem('policyDataTimestamp', new Date().toISOString());

        return data;
    }
);

const pagePolicySlice = createSlice({
    name: "policyData",
    initialState: {
        policyData: null,
        error: null,
        loading: "idle",
    },
    reducers: {},
    extraReducers: {
        [fetchPolicyData.pending]: (state) => {
            state.loading = "pending";
        },
        [fetchPolicyData.fulfilled]: (state, action) => {
            state.loading = "fulfilled";
            state.policyData = action.payload;
        },
        [fetchPolicyData.rejected]: (state, action) => {
            state.loading = "rejected";
            state.error = action.error.message;
        },
    },
});

export const { } = pagePolicySlice.actions;
export default pagePolicySlice.reducer;
export { fetchPolicyData };