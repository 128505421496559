import Header from "../components/Header";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "../components/SingleProductPage.css";
import SingleSlider from "../components/SingleSlider";
import SingleTabs from "../components/SingleTabs";

const SingleProductPage = ({ data, content }) => {
  const { pageData } = useSelector((state) => state.pageData);



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="container mx-auto text-center md:text-left my-4 md:pl-[18px] lg:pl-[25px] xl:pl-[36px] 2xl:pl-[0px] 2xl:max-w-[1350px] md:mt-[31px] md:mb-[60px]">
        <h4 className="text-[14px] px-4 md:px-0  text-[#acacac] font-normal font-nunito">
          {pageData.acf.strona_glowna.title} \ {pageData.acf.produkty.title}{" "}
          \ <b>{data.nazwa_produktu}</b>
        </h4>
      </div>
      <SingleSlider data={data} fields={pageData.acf} />
      <SingleTabs data={data} fields={pageData.acf} content={content} />
      <Footer />
    </>
  );
};
export default SingleProductPage;
