import Logo from "../assets/multigastro_logo-1.png";
import FB from "../assets/facebook-f.svg";
import YT from "../assets/youtube.svg";
import arrow from "../assets/white--arrow.svg";
import Toggle from "./Toggle";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Header = () => {
  const { pageData } = useSelector((state) => state.pageData);
  

  const handleClickScroll = () => {
    const element = document.getElementById("contact-form");
    element.scrollIntoView({ behavior: "smooth" });
  };

  

  if (Object.keys(pageData).length !== 0) {
    return (
      <>
        <header className="hidden lg:flex container md:container mx-auto py-12  justify-center	">
          <div className="max-w-[1350px] flex justify-between  items-center	  w-full">
            <Link to="/">
              <img
                className="md:max-w-[14rem] xl:max-w-full"
                src={pageData.acf.logo}
                alt="logo"
              />
            </Link>
            <ul className="flex items-center max-w-[215px] w-full justify-between">
              <Link to={pageData.acf.strona_glowna.url}>
                <li className="text-base font-bold font-nunito">
                  {pageData.acf.strona_glowna.title}
                </li>
              </Link>
              <Link to={pageData.acf.produkty.url}>
                <li className="text-base font-bold font-nunito">
                  {pageData.acf.produkty.title}
                </li>
              </Link>
            </ul>
            <ul className="flex items-center max-w-[248px] w-full justify-between">
              <a href={"tel:" + pageData.acf.telefon_header}>
                <li className="text-sm font-bold font-nunito">
                  {pageData.acf.telefon_header}
                </li>
              </a>
              <a href={"mailto:" + pageData.acf.mail_header}>
                <li className="text-sm font-bold font-nunito">
                  {pageData.acf.mail_header}
                </li>
              </a>
            </ul>
            <ul className="flex items-center max-w-[57px] w-full justify-between">
              <a href={pageData.acf.facebook}>
                <img src={FB} alt="" />
              </a>
              <a href={pageData.acf.youtube}>
                <img src={YT} alt="" />
              </a>
            </ul>
            <button
              onClick={handleClickScroll}
              className="max-h-[43px] items-center flex bg-default-red hover:bg-default-red/75 text-white font-bold p-4  rounded-full"
            >
              <p
                
                className="flex text-base font-bold font-nunito"
              >
                {pageData.acf.przycisk.title}{" "}
                <img className="ml-3" src={arrow} alt="" />
              </p>
            </button>
          </div>
        </header>
        <header className="block lg:hidden ">
          <Link to="/">
            <img
              className="m-5 max-w-[12rem] sm:max-w-[18rem]"
              src={Logo}
              alt=""
            />
          </Link>

          <Toggle
            className="flex lg:hidden"
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
          />
        </header>
      </>
    );
  }
};
export default Header;
