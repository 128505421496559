import "./Process.css";
import phone from "../assets/phone-call.svg";
import mail from "../assets/feather-mail.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import veneo from "../assets/logoVeneo.svg"
import Form from "./Form";
import Cf7FormWrapper from "../utilities/api/Cf7FormWrapper";
import { useSelector } from "react-redux";

const FormInfo = () => {
  const { pageData } = useSelector((state) => state.pageData);

  const API = process.env.REACT_APP_FORM_API_KEY;

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerMode: true,
    
    arrows: true,
    responsive: [
      {
        breakpoint: 1624,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          centerPadding: '0px',
          centerMode: true,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  if (Object.keys(pageData).length !== 0) {
    return (
      <div id="contact-form" className="h-auto pt-20 pb-[35px] md:pt-[100px] custom--bg relative">
        <div className="container mx-auto ">
          <div className="lg:max-w-[580px] flex justify-center">
            <h1 className="text-[32px] text-center lg:text-left max-w-[315px] mb-[40px] text-white font-bold font-nunito">
              {pageData.acf.skontaktuj_sie_z_nami}
            </h1>
          </div>
          <div className="xl:p-0 items-start flex-col	md:flex-row p-5 flex mx-auto justify-between max-w-[1269px]">
            <div className="lg:max-w-[580px] flex flex-col justify-center text-white">
              <p
                dangerouslySetInnerHTML={{
                  __html: pageData.acf.kontakt_tresc_po_lewej_stronie
                    .replace(/<(?!strong|br)\b[^>]*>/gi, "")
                    .replace(/<br\s*[\/]?>/gi, "</div><div>"),
                }}
                className="text-[18px] font-normal mb-[22px] font-nunito"
              />

              <div className="flex mb-6">
                <img className="mr-[13px]" src={phone} alt="phone" />
                <a href={"tel:" + pageData.acf.numer_telefonu}>
                  <p className="text-[18px] font-normal font-nunito">
                    {pageData.acf.numer_telefonu}
                  </p>
                </a>
              </div>
              <div className="flex">
                <img className="mr-[13px]" src={mail} alt="mail" />
                <a href={"mailto:" + pageData.acf.email}>
                  <p className="text-[18px] font-normal font-nunito">
                    {pageData.acf.email}
                  </p>
                </a>
              </div>
              <div className="flex flex-col mt-[48px]">
                <a href={pageData.acf.polityka_prywatnosci_i_cookies.url}>
                  <p className="text-[18px] underline font-normal font-nunito">
                    {pageData.acf.polityka_prywatnosci_i_cookies.title}
                  </p>
                </a>
                <a href={pageData.acf.regulamin.url}>
                  <p className="text-[18px] underline font-normal font-nunito">
                    {pageData.acf.regulamin.title}
                  </p>
                </a>
              </div>
            </div>
            <Cf7FormWrapper url={API}>
              <Form />
            </Cf7FormWrapper>
          </div>
          <div className="flex mx-auto justify-center lg:justify-between max-w-[1269px]">
            <h1 className="text-[32px] text-center lg:text-left max-w-[410px] mt-[40px] mb-[40px] md:mb-[90px] text-white font-bold font-nunito">
              {pageData.acf.do_tej_pory_zaufali_nam}
            </h1>
          </div>
          <div className="flex mx-auto flex-col max-w-[1370px] w-[95%]">
             <Slider {...settings}>
              {pageData.acf.kontakt_slider_.map((item) => {
                return (
                  <div className="footer--slider--img" key={item.id}>
                    <img alt={item.title} src={item.url} />
                  </div>
                );
              })}
            </Slider>
            
          </div>
          <div className="flex flex-col items-center">
            <h6 className="text-[18px] mt-16 lg:mt-36 text-center text-white font-normal px-1 font-nunito relative">
              {pageData.acf.copyrights}
            </h6>
            <a rel="noreferrer" href="https://veneo.pl/" target="_blank"><img src={veneo} /></a>
          </div>
        </div>
      </div>
    );
  }
};

export default FormInfo;
