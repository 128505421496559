import React from "react";
import { bubble as Menu } from "react-burger-menu";
import "./Toggle.css";
import FB from "../assets/facebook-f.svg";
import YT from "../assets/youtube.svg";
import arrow from "../assets/white--arrow.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Logo from "../assets/multigastro_logo-1.png";


const Toggle = () => {
  const { pageData } = useSelector((state) => state.pageData);

  const handleClickScroll = () => {
    const element = document.getElementById("contact-form");
    element.scrollIntoView({ behavior: "smooth" });
  };

  if (Object.keys(pageData).length !== 0) {
    return (
      <>
        <Menu>
          <Link to="/">
            <img
              className="m-0 mb-[20px] max-w-[9rem] sm:max-w-[18rem]"
              src={Logo}
              alt=""
            />
          </Link>
          <Link className="menu-item" to={pageData.acf.strona_glowna.url}>
            {pageData.acf.strona_glowna.title}
          </Link>
          <Link
            className="menu-item "
            to={"/" + pageData.acf.produkty.title.toLowerCase()}
          >
            {pageData.acf.produkty.title}
          </Link>
          <a
            className="menu-item  mt-[20px]"
            href={"tel:" + pageData.acf.telefon_header}
          >
            {pageData.acf.telefon_header}
          </a>
          <a
            className="menu-item "
            href={"mailto:" + pageData.acf.mail_header}
          >
            {pageData.acf.mail_header}
          </a>
          <ul className="flex mt-[20px] flex--socials items-center max-w-[57px] w-full justify-between">
            <a href={pageData.acf.facebook}>
              <img src={FB} alt="fb" />
            </a>
            <a href={pageData.acf.youtube}>
              <img src={YT} alt="yt" />
            </a>
          </ul>
          <button onClick={handleClickScroll} className="max-h-[43px] mobile--btn items-center flex bg-default-red hover:bg-default-red/75 text-white font-bold  rounded-full">
            <p
              
              className="btn--inside flex text-base font-bold font-nunito"
            >
              {pageData.acf.przycisk.title}
              <img className="ml-3" src={arrow} alt="" />
            </p>
          </button>
        </Menu>
      </>
    );
  }
};

export default Toggle;
