import ImageSlider from "./ImageSlider";
import "./ImageSlider.css";
import AboutUs from "./AboutUs";
import Products from "./Products";
import Process from "./Process";

const Content = () => {
  return (
    <>
      <ImageSlider />
      <AboutUs />
      <Products  />
      <Process />
    </>
  );
};

export default Content;
