import { configureStore } from '@reduxjs/toolkit'
import pageDataReducer from '../slices/fetchData'
import postDataReducer from '../slices/fetchPost'
import productsDataReducer from '../slices/fetchProducts'
import policyDataReducer from '../slices/fetchPolicy'
import infoDataReducer from '../slices/fetchInfo'


export const store = configureStore({
  reducer: {
    pageData: pageDataReducer,
    policyData: policyDataReducer,
    infoData: infoDataReducer,
    productsData: productsDataReducer,
    postData: postDataReducer,
  },
})