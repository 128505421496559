import React, { useState } from "react";
import Slider from "react-slick";
import arrowLeft from "../assets/productRight.svg";
import arrowRight from "../assets/productLeft.svg";

const CustomArrowLeft = ({ style, onClick }) => {
  return (
    <img
      src={arrowLeft}
      className={"left-arrowProduct block"}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const CustomArrowRight = ({ style, onClick }) => {
  return (
    <img
      src={arrowRight}
      className={"right-arrowProduct block"}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
};

const SingleProductSlider = ({ data }) => {
  const [slider, setSlider] = useState(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomArrowLeft />,
    nextArrow: <CustomArrowRight />,
    afterChange: (current) => setSlider(current),
    centerMode: false,
  };

  return (
    <div className="single-product-slider">
      <Slider {...settings}>
        {data.galeria_zdjec.map((item) => {
          return (
            <div>
          <img
            src={item}
            alt="Image 1"
          />
        </div>
          )
        })}
        
      </Slider>
    </div>
  );
};

export default SingleProductSlider;
