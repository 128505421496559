import React from "react";

export default function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        fontSize: "2rem",
        cursor: "pointer",
        backgroundColor: "rgb(231, 52, 91)",
        padding: "0px",
        maxWidth: "40px",
        height: "100%",
        zIndex: "999",
        borderRadius: "50%",
        maxHeight: "40px",
        width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "url(https://multigastro.ro/wp/wp-content/uploads/2023/02/Path-114.png)",
        backgroundSize: "auto",
        userSelect: "none",
      }}
      onClick={onClick}
    />
  );
}
