import arrow from "../assets/white--arrow.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SingleProductPage from "../pages/SingleProductPage";
import { LazyLoadImage } from "react-lazy-load-image-component";


const ProductsList = ({ data }) => {
  const { pageData } = useSelector((state) => state.pageData);


  const exportedData = data.map((item) => ({
    id: item.id,
    acf: item.acf,
    slug: item.slug,
  }));

  return (
    <>
      <div className="container mx-auto text-center md:text-left  md:pl-[18px] lg:pl-[25px] xl:pl-[36px] 2xl:pl-[6px] 2xl:max-w-[1350px] ">
        <h2 className="text-[32px] mb-[30px] md:mb-[80px] font-bold font-nunito">
          {pageData.acf.produkty.title}
        </h2>
      </div>
      <div>
        <>
          <div className="container mx-auto flex max-w-[1350px] flex-wrap justify-evenly xl:justify-between">
            {exportedData.map((item) => {
              return (
                <div
                  className="w-[385px] flex flex-col items-center text-center lg:text-left lg:block  "
                  key={item.id}
                >
                  <Link to={item.slug}>
                    {" "}
                    <LazyLoadImage alt={item.acf.title} src={item.acf.zdjecie_glowne} />
                  </Link>
                  <Link to={item.slug}>
                    <h3 className="max-w-[320px] mt-[35px] first--word mb-[10px] text-[18px] font-normal font-nunito">
                      {item.acf.nazwa_produktu}
                    </h3>
                  </Link>

                  <h4 className="text-[26px] text-default-red font-bold mb-[20px] font-nunito">
                    {item.acf.cena} RON
                  </h4>
                  <button className="max-h-[43px] w-auto mb-16 lg:mb-[130px]  w-[110px] flex justify-center items-center flex bg-black hover:bg-black/75 text-white font-bold p-4  rounded-[8px]">
                    <Link
                      component={<SingleProductPage />}
                      to={item.slug}
                      className="flex text-base font-bold font-nunito"
                    >
                      {pageData.acf.przycisk_pokaz_przy_produkcie}
                      <img className="ml-3" src={arrow} alt="arrow" />
                    </Link>
                  </button>
                </div>
              );
            })}
          </div>
        </>
      </div>
    </>
  );
};

export default ProductsList;
