import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const API = process.env.REACT_APP_DATA_API_KEY;

const fetchPageData = createAsyncThunk(
    "pageData/fetchPageData",
    async (args, { getState, dispatch }) => {
        const localStorageData = localStorage.getItem('pageData');
        const dataAge = localStorage.getItem('pageDataTimestamp');
        const now = new Date();

        // Sprawdzenie czy dane nie są starsze niż 24 godziny (3600000 milisekund)
        if (localStorageData && dataAge && now - new Date(dataAge) < 1800000) {
            return JSON.parse(localStorageData);
        }

        // Pobieranie danych, jeśli nie ma ich w localStorage lub są przestarzałe
        const response = await fetch(`${API}/81?acf_format=standard`);
        if (!response.ok) {
            throw new Error("Sorry something went wrong");
        }
        const data = await response.json();

        // Zapisanie pobranych danych w localStorage wraz z timestampem
        localStorage.setItem('pageData', JSON.stringify(data));
        localStorage.setItem('pageDataTimestamp', new Date().toISOString());

        return data;
    }
);

const pageDataSlice = createSlice({
    name: "pageData",
    initialState: {
        pageData: null,
        error: null,
        loading: "idle",
    },
    reducers: {},
    extraReducers: {
        [fetchPageData.pending]: (state) => {
            state.loading = "pending";
        },
        [fetchPageData.fulfilled]: (state, action) => {
            state.loading = "fulfilled";
            state.pageData = action.payload;
        },
        [fetchPageData.rejected]: (state, action) => {
            state.loading = "rejected";
            state.error = action.error.message;
        },
    },
});

export const { } = pageDataSlice.actions;
export default pageDataSlice.reducer;
export { fetchPageData };